<template>
  <div>
    <div class="card-marketing" :class="colorCard">
      <b-col
        class="text-white d-flex flex-column justify-content-center pl-3 col-12 col-sm-8"
      >
        <div>
          <b-badge :class="colorCard === 'card-white' ? 'badge-white' :'badge-marketing'"><span class="badge-text" :class="{'text-dark': colorCard === 'card-white'}">{{ textBadge }}</span></b-badge>
        </div>
        <div class="mt-1">
            <span class="text-information" :class="{'text-dark': colorCard === 'card-white'}">{{ textInfo }}</span>
        </div>
        <div>
          <b-button 
            :variant="colorCard === 'card-white' ? 'blue-button-marketing' :'light'" 
            :class="colorCard === 'card-white' ? 'blue-button-marketing' :'light'" 
            class="my-1 avenir-medium"
            @click="reference === 'open_modal_membership' ? openModalMembership() : isUrl(reference) ? redirectHref() : $router.push(reference)"
          >{{ textButton }} </b-button>
        </div>
        <div class="container-link" :class="{'text-dark': colorCard === 'card-white'}">
          <span>{{ textLink }}</span> <b-link class="link" target="_blank" :href="reference_link" :class="{'text-white': colorCard !== 'card-white'}"> {{ link }}</b-link>
        </div>
      </b-col>
      <b-col class="col-4 img-responsive-sm">
        <div class="images-inner-first">
          <b-img :src="img" class="img-marketing opacity-25"/>
          <b-img :src="img" class="img-marketing opacity-20"/>
          <b-img :src="img" class="img-marketing opacity-25"/>
        </div>
      </b-col>
    </div>
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      :month_dad="true"
      :texts="{title: $t('creator.msjTitlePremium+Brand'), subtitle: $t('creator.subtitlePremium+Brand')}"
    />
  </div>
</template>
  
<script>
import { BBadge, BButton, BCol, BImg, BLink, } from "bootstrap-vue";
import { isUrl } from '@/libs/utils/urls'

export default {
  name: "collectionCardNetwork",
  components: {
    BLink,
    BBadge,
    BButton,
    BCol,
    BImg,
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
  },
  props: {
    textBadge: {
      type: String,
      default: 'ENTERPRISE INFLUENCER MARKETING SOFTWARE'
    },
    textInfo: {
      type: String,
      default: 'Manage all your creators, campaigns, payments, and performance.'
    },
    textButton: {
      type: String,
      default: 'Get started for free'
    },
    textLink: {
      type: String,
      default: 'Find out more about'
    },
    link: {
      type: String,
      default: 'BrandMe flex'
    },
    colorCard: {
      type: String,
      default: 'color-card'
    },
    img: {
      type: String,
      default: ''
    },
    reference: {},
    reference_link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isUrl,
      open_modal_plans_group: false,
      update_modal: false,
    }
  },
  methods: {
    openModalMembership() {
      this.open_modal_plans_group = true;
      this.update_modal = !this.update_modal;
    },
    redirectHref() {
      window.location.href = this.reference
    }
  }
};
</script>
  
<style scoped>
@media (max-width: 576px) {
.img-responsive-sm {
  position: relative;
  right: 150px;
}
.opacity-20 {
  opacity: 0.20;
}

}
.blue-button-marketing {
  background-color: #0096fe !important;
  border: 0 !important;
  color: white !important;
  padding: 10px 25px;
} 
.blue-button-marketing:hover {
  background-color:rgb(77, 182, 249) !important;
  box-shadow: rgba(77, 182, 249, 0.3) 0px 7px 29px 0px !important;  
  color: white !important;
}

.card-secondary {
  background: linear-gradient(to left bottom, #fcd751, #e94287) !important;
}
.card-white {
  background-color: white;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  color: black !important;
}
.color-card {
    background: linear-gradient(to left bottom, #743eb5, #421150);
}  
.container-link:hover {
  color: rgba(243, 243, 243, 0.582);  
}
.container-link {
  font-family: 'avenir-medium';
}  
.link {
  text-decoration-line: underline;
}
.badge-text {
  font-size: 10px;
}
.text-information {
  font-size: calc(1.2890625rem + 0.46875vw);
  font-weight: bold;
  line-height: 1.2;
}
.card-marketing {
  min-height: 295px;
  border-radius: 1.5em;
  display: flex;
  overflow: hidden;
}
.badge-marketing {
  background-color: rgba(249, 250, 252, 0.2) !important;
}
.badge-white {
  background-color: #d8d8d8cc !important;
}
.img-marketing {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 1em;
  border-radius: 100%;
}
.images-inner-first {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: -4em;
}
.images-inner-secondary {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: -2em;
}
.opacity-25 {
  opacity: 0.25;
}
</style>